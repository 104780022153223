<template>
  <action-menu-wrapper :items="options" :offset="offset" teleport />
  <confirm-modal
    v-if="showDeleteModal"
    :title="$t('ORGANIZATION.EMAIL_TEMPLATES.DELETE_TITLE')"
    :description="$t('ORGANIZATION.EMAIL_TEMPLATES.DELETE_DESCRIPTION')"
    :confirm-button="$t('COMMON.DELETE')"
    confirm-button-type="error"
    :close="() => (showDeleteModal = false)"
    @confirm="handleDelete"
  />
</template>

<script setup lang="ts">
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

import { ref, computed, type PropType } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useClients } from "@/hooks/clients";
import { usePromiseWrapper } from "@/hooks/common";
import customizationApiService from "@/services/modules/customization";
import ActionMenuWrapper, {
  type MenuItem
} from "@/components/ui/ActionMenuWrapperV2.vue";
import { ROUTE_EMAIL_TEMPLATES_EDIT } from "@/router/routes";
import { useAuth } from "@/hooks/auth";
import { EmailTemplateVisibility } from "@/enums/emailCustomization";
import type { EmailTemplate } from "@/models/clients";

const emit = defineEmits<{
  "template:deleted": [];
  "template:duplicated": [];
  "template:updated": [];
}>();

const props = defineProps({
  model: {
    type: Object as PropType<EmailTemplate>,
    required: true
  }
});

const { t } = useI18n();
const router = useRouter();
const { isClientAdmin, isAdmin } = useAuth();
const { activeClient } = useClients();

const showDeleteModal = ref(false);

const widthOffset = computed(() =>
  props.model?.visibility === EmailTemplateVisibility.Organizational
    ? -118
    : -154
);
const offset = computed(() => ({ left: widthOffset.value, top: 43 }));

const options = computed<Array<MenuItem>>(() => [
  { label: t("COMMON.EDIT"), handler: handleEdit },
  { label: t("COMMON.DUPLICATE"), handler: handleDuplicate },
  ...getVisibilityOptions(),
  { label: t("COMMON.DELETE"), handler: () => (showDeleteModal.value = true) }
]);

async function handleEdit() {
  const clientId = activeClient.value?.id;
  if (!props.model?.id) {
    return;
  }
  const pushPayload = {
    name: ROUTE_EMAIL_TEMPLATES_EDIT,
    params: {
      id: clientId,
      templateId: props.model?.id
    }
  };

  await router.push(pushPayload);
}

const getVisibilityOptions = () => {
  const isAdminRole = isClientAdmin || isAdmin;

  const options: MenuItem[] = [];

  if (!isAdminRole) {
    return options;
  }

  const organizationalOption = {
    label: t("ORGANIZATION.EMAIL_TEMPLATES.SET_AS_ORGANIZATIONAL"),
    handler: () =>
      handleUpdate({ visibility: EmailTemplateVisibility.Organizational })
  };

  const globalOption = {
    label: t("ORGANIZATION.EMAIL_TEMPLATES.SET_AS_GLOBAL"),
    handler: () => handleUpdate({ visibility: EmailTemplateVisibility.Global })
  };

  const personalOption = {
    label: t("ORGANIZATION.EMAIL_TEMPLATES.SET_AS_PERSONAL"),
    handler: () =>
      handleUpdate({ visibility: EmailTemplateVisibility.Personal })
  };

  if (props.model.visibility !== EmailTemplateVisibility.Personal) {
    options.push(personalOption);
  }

  if (props.model.visibility !== EmailTemplateVisibility.Organizational) {
    options.push(organizationalOption);
  }

  if (props.model.visibility !== EmailTemplateVisibility.Global && isAdmin) {
    options.push(globalOption);
  }

  return options;
};

const { fetchWrapper: handleDuplicate } = usePromiseWrapper(async () => {
  if (!props.model.id) {
    return;
  }
  await customizationApiService.duplicateEmailTemplate(props.model.id);
  emit("template:duplicated");
});

const { fetchWrapper: handleDelete } = usePromiseWrapper(async () => {
  if (!props.model.id) {
    return;
  }
  await customizationApiService.deleteEmailTemplate(props.model.id);
  emit("template:deleted");
});

const { fetchWrapper: handleUpdate } = usePromiseWrapper(
  async (updateValues: Partial<EmailTemplate>) => {
    if (!props.model.id) {
      return;
    }

    const payload = {
      ...props.model,
      ...updateValues
    };
    await customizationApiService.updateEmailTemplate(payload);
    emit("template:updated");
  }
);
</script>
